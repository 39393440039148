import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://4bfc05bf4b6a0ffaacd304ae1bde7764@o4507569804935168.ingest.us.sentry.io/4507569807163392",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});